import React from 'react'
import GalleryBackground from '../../components/galleryBackground'
import GalleryGridFancy from '../../components/galleryGridFancy'
import foundStagesRo from "../../images/rollovers/graphic-design/foundstages-ro.png"

import img1 from '../../images/graphic-design/found-stages/1.jpg'
import img2 from '../../images/graphic-design/found-stages/2.jpg'
import img3 from '../../images/graphic-design/found-stages/3.jpg'
import img4 from '../../images/graphic-design/found-stages/4.jpg'
import img5 from '../../images/graphic-design/found-stages/5.jpg'
import img6 from '../../images/graphic-design/found-stages/6.jpg'
import img7 from '../../images/graphic-design/found-stages/7.jpg'
import img8 from '../../images/graphic-design/found-stages/8.jpg'
import img9 from '../../images/graphic-design/found-stages/9.jpg'
import img10 from '../../images/graphic-design/found-stages/10.jpg'
import img11 from '../../images/graphic-design/found-stages/11.jpg'
import img12 from '../../images/graphic-design/found-stages/12.jpg'
import img14 from '../../images/graphic-design/found-stages/14.jpg'
import img15 from '../../images/graphic-design/found-stages/15.jpg'
import img16 from '../../images/graphic-design/found-stages/16.jpg'
import img17 from '../../images/graphic-design/found-stages/17.jpg'
import img18 from '../../images/graphic-design/found-stages/18.jpg'
import img19 from '../../images/graphic-design/found-stages/19.jpg'
import img20 from '../../images/graphic-design/found-stages/20.jpg'
import img21 from '../../images/graphic-design/found-stages/21.jpg'
import img22 from '../../images/graphic-design/found-stages/22.jpg'
import vid1 from '../../images/graphic-design/found-stages/sound.mp4'
import vid1tab from '../../images/graphic-design/found-stages/dance_card_icon.jpg'
import "../layout.css"

export default function FoundStages() {
    let images = [img1, img2, img3, img4, img5, img6, img9, img7, img8, img10, img11, img12, img15, img16, img17, img18, img19, img20, img21, img22]
    let vids = [{vid:vid1,tab:vid1tab}]
    return (
        <GalleryBackground title="Found Stages" imgSrc={foundStagesRo}>
            <GalleryGridFancy imgArray={images} vidArray={vids} />
        </GalleryBackground>
    )
}